import React from "react";

const FAQs = () => {
  return (
    <div>
      <svg
        width="18"
        height="7"
        viewBox="0 0 18 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8179 6.15376C17.5619 5.90288 17.299 5.65844 17.0222 5.42686C16.8769 5.30464 16.8838 5.22101 16.9737 5.06663C17.728 3.77365 17.7072 2.4871 16.8215 1.26489C15.6936 -0.298265 13.4239 -0.433353 12.1299 0.975414C11.3273 1.85026 11.0643 2.87307 11.2996 3.99236C11.4657 4.79645 11.8947 5.45902 12.5866 5.96721C13.4724 6.61048 14.8217 6.84206 16.1641 5.96078C16.4755 6.25668 16.7731 6.54615 17.0775 6.82919C17.3197 7.04791 17.6242 7.05434 17.8387 6.86136C18.0601 6.66194 18.0532 6.38534 17.8179 6.15376ZM16.0603 4.57131C15.7766 4.2947 15.5206 4.03739 15.2507 3.78651C14.9947 3.5485 14.6902 3.52921 14.4688 3.72862C14.2405 3.92803 14.2612 4.22394 14.5242 4.47482C14.8009 4.73856 15.0847 4.99587 15.3614 5.25961C14.8632 5.6713 13.9637 5.6906 13.334 5.27891C12.4759 4.71283 12.1784 3.9023 12.2753 2.96313C12.3306 2.40991 12.5659 1.92746 12.988 1.5222C13.7353 0.808163 14.8494 0.782432 15.6451 1.45144C16.5239 2.1912 16.7385 3.66429 16.0603 4.57131Z"
          fill="#212D65"
        />
        <path
          d="M10.5459 6.1151C9.73632 4.32681 8.9198 2.53851 8.11712 0.75021C8.02024 0.531498 7.86801 0.383545 7.59814 0.383545C7.33519 0.383545 7.18988 0.525065 7.093 0.737345C6.27648 2.53851 5.45304 4.3461 4.64343 6.14727C4.59499 6.25662 4.58115 6.40458 4.60883 6.52036C4.66419 6.72621 4.83026 6.842 5.05861 6.85487C5.3008 6.86773 5.47379 6.76481 5.57067 6.55896C5.79902 6.06364 6.02737 5.56832 6.2488 5.073C6.30416 4.95078 6.35952 4.88002 6.52559 4.88002C6.87849 4.89289 7.2314 4.88645 7.59122 4.88645C7.95104 4.88645 8.30395 4.89289 8.65685 4.88002C8.82293 4.87359 8.89904 4.93148 8.96132 5.073C9.16891 5.54902 9.38342 6.01861 9.60485 6.49463C9.75016 6.80984 10.02 6.92563 10.3037 6.8227C10.5944 6.70691 10.6982 6.44317 10.5459 6.1151ZM6.78854 3.90225C7.05148 3.31043 7.31443 2.73792 7.60506 2.10108C7.88877 2.73149 8.15172 3.304 8.42158 3.90225H6.78854Z"
          fill="#212D65"
        />
        <path
          d="M1.05179 1.34202C1.05179 1.8695 1.05179 2.36482 1.05179 2.89874C1.14175 2.89874 1.23862 2.89874 1.32858 2.89874C2.11743 2.89874 2.89935 2.89874 3.68819 2.89874C3.78507 2.89874 3.88195 2.89874 3.9719 2.91804C4.22101 2.97593 4.38708 3.19465 4.36632 3.41979C4.33865 3.67067 4.13105 3.85722 3.84043 3.85722C3.01007 3.86365 2.1797 3.85722 1.34934 3.85722C1.25938 3.85722 1.16251 3.85722 1.03795 3.85722C1.03795 3.96657 1.03795 4.0502 1.03795 4.14026C1.03795 4.88002 1.03795 5.61978 1.03795 6.35955C1.03795 6.45604 1.03103 6.55253 1.01027 6.64259C0.947998 6.87417 0.719648 7.01568 0.463619 6.98995C0.22143 6.96422 0.0415181 6.79697 0.0138394 6.56539C0 6.50107 0 6.43031 0 6.35311C0 4.57768 0 2.80225 0 1.02682C0 0.525065 0.152233 0.383545 0.685049 0.383545C1.723 0.383545 2.76096 0.383545 3.79891 0.383545C4.13797 0.383545 4.36632 0.582959 4.35941 0.859567C4.35941 1.12331 4.13797 1.32272 3.81967 1.34202C3.75047 1.34845 3.68128 1.34202 3.61208 1.34202C2.85783 1.34202 2.11051 1.34202 1.35626 1.34202C1.2663 1.34202 1.17635 1.34202 1.05179 1.34202Z"
          fill="#212D65"
        />
      </svg>
    </div>
  );
};

export default FAQs;

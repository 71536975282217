import React from "react";

const About = () => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0C3.59365 0 0 3.59365 0 8C0 12.4064 3.59365 16 8 16C12.4064 16 16 12.4064 16 8C16 3.59365 12.4064 0 8 0ZM8 14.9841C4.15238 14.9841 1.01587 11.8476 1.01587 8C1.01587 4.15238 4.15238 1.01587 8 1.01587C11.8476 1.01587 14.9841 4.15238 14.9841 8C14.9841 11.8476 11.8476 14.9841 8 14.9841Z"
          fill="#212D65"
        />
        <path
          d="M8.50806 6.7937H7.49219V11.8731H8.50806V6.7937Z"
          fill="#212D65"
        />
        <path
          d="M8.50806 4.38086H7.49219V5.39673H8.50806V4.38086Z"
          fill="#212D65"
        />
      </svg>
    </div>
  );
};

export default About;

import React from "react";

const ContactUs = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0425 16.7722L13.8861 14.9054C14.0295 14.7621 14.1433 14.592 14.221 14.4047C14.2986 14.2174 14.3386 14.0167 14.3386 13.8139C14.3386 13.6112 14.2986 13.4104 14.221 13.2231C14.1433 13.0358 14.0295 12.8657 13.8861 12.7224L12.837 11.6965C12.6937 11.553 12.5236 11.4392 12.3363 11.3616C12.149 11.284 11.9483 11.244 11.7455 11.244C11.5428 11.244 11.342 11.284 11.1547 11.3616C10.9674 11.4392 10.7973 11.553 10.654 11.6965L9.95977 12.3907C8.17259 11.3194 6.67597 9.82541 5.60146 8.04013L6.29571 7.34588C6.43915 7.2026 6.55294 7.03245 6.63058 6.84516C6.70822 6.65788 6.74818 6.45712 6.74818 6.25438C6.74818 6.05163 6.70822 5.85088 6.63058 5.66359C6.55294 5.4763 6.43915 5.30615 6.29571 5.16287L5.25434 4.10608C5.11054 3.95795 4.93847 3.8402 4.74833 3.75979C4.5582 3.67937 4.35385 3.63794 4.14741 3.63794C3.94096 3.63794 3.73662 3.67937 3.54648 3.75979C3.35634 3.8402 3.18427 3.95795 3.04047 4.10608L1.22773 5.95739C1.10137 6.08292 1.02235 6.2483 1.00407 6.42546C0.985796 6.60263 1.02939 6.78066 1.12745 6.92934C3.74952 10.8704 7.1295 14.2504 11.0706 16.8725C11.2192 16.9705 11.3973 17.0141 11.5744 16.9958C11.7516 16.9775 11.917 16.8985 12.0425 16.7722Z"
          stroke="#212D65"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.17236 1.86917C7.54937 1.13116 9.1262 0.852552 10.6727 1.07399C12.2193 1.29544 13.6546 2.00534 14.7691 3.10011C15.8837 4.19488 16.6193 5.61721 16.8684 7.15953C17.1175 8.70184 16.8672 10.2834 16.154 11.6734"
          stroke="#212D65"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.98291 3.85938V8.17911H13.0684"
          stroke="#212D65"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ContactUs;
